<template>
    <div>
        <div style="margin: 1rem; display: flex; gap: 1rem">
            <!--<button class="download" @click.prevent="download">Download</button>-->
            <button class="print" @click.prevent="printPdf">Preview</button>
        </div>
        <div style="display: flex; justify-content: space-around">
            <vue-html2pdf
                    :show-layout="false"
                    :float-layout="false"
                    :paginate-elements-by-height="800"
                    :enable-download="true"
                    :preview-modal="true"
                    :filename="selectedSite.site_name"
                    :htmlToPdfOptions="{
                        margin: [10, 10],
                        filename: selectedSite.site_name
                    }"
                    :pdf-quality="2"
                    :manual-pagination="true"
                    :margin="[50, 50]"
                    pdf-content-width="700px"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    ref="html2Pdf"

                    @progress="onProgress($event)"
                    @startPagination="startPagination()"
                    @hasPaginated="hasPaginated()"
                    @beforeDownload="beforeDownload($event)"
                    @hasDownloaded="hasDownloaded($event)"
            >
                <div class="body" slot="pdf-content">
                    <div class="container">
                        <div class="container_header">
                            <h5><b>Point-of-Care Technology Continuous Quality Improvement
                                <br>For Early Infant Diagnosis Program
                            </b></h5>
                            <h5>{{country.country_name}} ({{country.country_prefix}})</h5>
                        </div>
                        <div class="thead-1" style="border: 1px solid; border-collapse: collapse">
                            <h6 style="padding: 5px">Participant Result Summary (Résumé des résultats du site) – Panel {{ptData.ptround.year}}-B</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="border-right: 1px solid; padding: 5px; "><h6>Site Name</h6></div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Date PT Received</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">{{downloadFile.details[site_id].receieved}}</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="padding: 5px; border-right: 1px solid;"><h6>{{selectedSite.site_name}}</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Date PT Results Reported</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">{{downloadFile.details[selectedSite.id].reported}}</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Site ID</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Instrument</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Reagent Lot No:</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">{{downloadFile.details[selectedSite.id].lotno}}</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;">{{selectedSite.ptid}}</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; ">{{downloadFile.details[selectedSite.id].instrument}}</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Expiry Date</h6></div>
                                <div class="col-lg-3">{{downloadFile.details[selectedSite.id].expiry}}</div>
                            </div>
                        </div>
                        <div class="thead-2">
                            <h6>Site Result Summary/ résultat du site</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around;">
                                <div class="col-lg-3" style="color: white"><h6></h6>Expected Result</div>
                                <div class="col-lg-2" style="border-left: 1px solid;border-right: 1px solid;border-top: 1px solid; padding: 5px;"><h6>{{ptData.ptround.year}} B1</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>{{ptData.ptround.year}} B2</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>{{ptData.ptround.year}} B3</h6></div>
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid; border-top: 1px solid;"><h6>Score</h6></div>
                            </div>
                            <div style="display: flex; flex-direction: column; text-align: center; justify-content: space-around; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex; text-align: center; justify-content: space-around;">
                                    <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;"><h6>Expected Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">{{downloadFile.details[selectedSite.id].er1 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].er1 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].er1 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">{{downloadFile.details[selectedSite.id].er2 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].er2 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].er2 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">{{downloadFile.details[selectedSite.id].er3 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].er3 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].er3 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6>{{downloadFile.details[selectedSite.id].perc}} %</h6></div>
                                </div>
                                <div style="display: flex; text-align: center; justify-content: space-around; border-collapse: collapse">
                                    <div class="col-lg-3" style="border-right: 1px solid;border-top: 1px solid; "><h6>Your Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">{{downloadFile.details[selectedSite.id].r1 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].r1 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].r1 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">{{downloadFile.details[selectedSite.id].r2 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].r2 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].r2 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">{{downloadFile.details[selectedSite.id].r3 === 0 ? "NEGATIVE" : downloadFile.details[selectedSite.id].r3 === 1 ? "POSITIVE" : downloadFile.details[selectedSite.id].r3 === 2 ? "INVALID" : ""}}</div>
                                    <div class="col-lg-3" style="color: white">Reagent Lot No:</div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-3">
                            <h6>Summary of All Site Scores/ Résumé des résultats de tous les sites</h6>
                            <div style="display: flex; flex-direction: column; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex;">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Participating Sites</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring below 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6><b>Percentage of Sites Scoring 100%</b></h6></div>
                                </div>
                                <div  style="display: flex; border-top: 1px solid">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>{{downloadFile.sites_qui_participe_a_cette_session}}</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>{{percent100.length}}</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>{{percentLess100.length}}</h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6>{{Math.floor((percent100.length/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</h6></div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-4">
                            <h6>Percentage of all Participating Sites reporting correctly</h6>
                            <h6>Pourcentage de tous les sites participants qui établissent correctement leurs rapports</h6>
                            <div style="display: flex">
                                <div class="col-lg-3" style="color: white">Correctly Reported</div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid; border-left: 1px solid;border-right: 1px solid;"><h6><b>{{ptData.ptround.year}} B1</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>{{ptData.ptround.year}} B2</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>{{ptData.ptround.year}} B3</b></h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>Average</b></h6></div>
                            </div>
                            <div style="display: flex; border: 1px solid;">
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid;"><b>Correctly Reported</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>{{Math.floor((res.e1/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>{{Math.floor((res.e2/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>{{Math.floor((res.e3/downloadFile.sites_qui_participe_a_cette_session)*100)}} %</b></div>
                                <div class="col-lg-3" style="padding: 5px;"> <b>{{Math.floor(((res.e1/downloadFile.sites_qui_participe_a_cette_session)*100+(res.e1/downloadFile.sites_qui_participe_a_cette_session)*100+(res.e1/downloadFile.sites_qui_participe_a_cette_session)*100)/3)}} %</b></div>
                            </div>
                        </div>
                        <div class="thead-5">
                            <h6>OPERATOR’S NAME : </h6>
                            <h6><b> {{downloadFile.details[selectedSite.id].operator_name}}</b></h6>
                        </div>

                    </div>
                </div>
            </vue-html2pdf>
        </div>
    </div>
</template>

<script>
    import {axios_get} from "../../../helpers/helper";
    import VueHtml2pdf from 'vue-html2pdf'
    export default {
        name: "review",
        components: {
            VueHtml2pdf
        },
        data(){
            return{
                country: JSON.parse(localStorage.getItem('user')).country,
                res: {e1: 0, e2: 0, e3: 0},
                percent100:[],
                downloadFile: null,
                percentLess100:[],
                site_id : this.$route.query.site_id,
                selectedSite : null,
                ptData : null,
                print : false,
                year : this.$route.query.year,
                pt_round :this.$route.query.pt_round
            }
        },
        mounted(){
            axios_get('/exportsitedata/'+this.site_id+'/'+this.pt_round+'?year='+this.year,{}, (d)=>{
                console.log(d)
                this.downloadFile = d
                console.log(this.downloadFile)
                Object.keys(d.details).map((e, i)=>{
                    if (d.details[e].perc === 100){
                        this.percent100.push(d.details[e])
                    }else{
                        this.percentLess100.push(d.details[e])
                    }
                    if (d.details[e].er1 === d.details[e].r1){
                        this.res.e1 = this.res.e1+1;
                    }
                    if (d.details[e].er2 === d.details[e].r2){
                        this.res.e2 = this.res.e2+1;
                    }
                    if (d.details[e].er3 === d.details[e].r3){
                        this.res.e3 = this.res.e3+1;
                    }
                    console.log(i)
                    console.log(this.percent100)
                    console.log(this.percentLess100)
                })
            }, (err)=>{
                console.log('error', err)
            }, (f)=>{
                console.log('finale', f)
                this.loader = false
            })

            axios_get("/sites/"+this.site_id,{},(d)=>{
                console.log(d)
                this.selectedSite = d[0]
                console.log(this.selectedSite)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );

            axios_get("/pt_rounds/"+this.pt_round,{},(da)=>{
                    console.log(da);
                    this.ptData=da;
                    console.log(this.ptData)
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        },
        methods: {
            async beforeDownload ({ html2pdf, options=this.htmlToPdfOptions, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    console.log(pdf)
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        //pdf.setPage(i)
                        //pdf.setFontSize(10)
                        //pdf.setTextColor(150)
                        //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    }
                }).save()
            },
            download(){
                this.$refs['html2Pdf'].generatePdf()
                /*this.selectedSite = e;
                console.log(this.selectedSite)
                this.percent100=[]
                this.percentLess100=[]
                axios_get('/exportsitedata/'+e.id+'/'+this.$route.params.id+'?year='+this.ptData.ptround.year,{}, (d)=>{
                    console.log(d)
                    this.downloadFile = d
                    console.log(this.downloadFile)
                    Object.keys(d.details).map((e, i)=>{
                        if (d.details[e].perc === 100){
                            this.percent100.push(d.details[e])
                        }else{
                            this.percentLess100.push(d.details[e])
                        }
                        if (d.details[e].er1 === d.details[e].r1){
                            this.res.e1 = this.res.e1+1;
                        }
                        if (d.details[e].er2 === d.details[e].r2){
                            this.res.e2 = this.res.e2+1;
                        }
                        if (d.details[e].er3 === d.details[e].r3){
                            this.res.e3 = this.res.e3+1;
                        }
                        console.log(i)
                        console.log(this.percent100)
                        console.log(this.percentLess100)
                    })
                    setTimeout(()=>{
                        this.$refs['html2Pdf'].generatePdf()
                    }, 1000)
                }, (err)=>{
                    console.log('error', err)
                }, (f)=>{
                    console.log('finale', f)
                    this.loader = false
                })*/
                //this.$refs['html2Pdf'].generatePdf()
            },
            printPdf(){
                this.print = true
                this.$refs['html2Pdf'].generatePreviewPdf()
            }
        }
    }
</script>

<style scoped>
    .download{
        padding: 1rem;
        background-color: #243b55;
        font-family: 'Montserrat';
        border: none;
        color: white;
    }
    .print{
        padding: 1rem;
        background-color: #179a73;
        font-family: 'Montserrat';
        border: none;
        color: white;
    }
    .body{
        background-color: white;
        padding: 2rem 2rem 5rem 2rem;
    }
    .container_header{
        text-align: center;
    }
    tbody, thead{
        width: 500px;
    }
    tr{
        text-align: center;
        font-size: 18px;
        margin: 1rem;
    }
    .thead-1,.thead-2,.thead-3,.thead-4{
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
    }
    .thead-5{
        display: flex;
        position: absolute;
        margin: 2rem;
    }
</style>